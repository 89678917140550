.project-card {
  direction: ltr;
  background-color: #f8f8f8;
  margin: 8px;
  padding: 24px;
  color: #616161;
  max-width: 600px;
}

.border-gradient {
  border: 8px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: conic-gradient(#5c9c73, #272a34, #80b085, #5c9c73);
}

.project-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

.project-card-titlegroup {
  display: flex;
  flex-direction: column;
}

.project-card-title {
  color: black;
  margin: auto;
  text-align: right;
}

.project-card-subtitle {
  color: grey;
  margin: auto;
  text-align: right;
  margin-right: 0;
}

.project-card-img-content {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}

.project-card-description {
  font-size: 1rem;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
}

/*---------        FLOAT       ---------*/

.float-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.float-on-hover:hover,
.float-on-hover:focus,
.float-on-hover:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
