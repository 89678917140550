.wrapper {
  width: 100%;
  height: 100%;
}

.main-container {
  flex-direction: column;
  align-items: center;
  margin: 8px;
  display: flex;
}

.timeline {
  width: 90%;
  margin: 50px auto;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
}

.timeline-content {
  color: #666;
  width: 95%;
  padding: 0 15px;
}

.timeline:before {
  content: "";
  z-index: 1;
  background: #ccd1d9;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  left: 50%;
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  clear: both;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  width: calc(50% + 8px);
  display: flex;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  z-index: 9999;
  background-color: #ccd1d9;
  border-radius: 5%;
  width: 16px;
  height: 16px;
  margin-top: 10px;
  transition-property: background-color;
  transition-duration: .3s;
  transition-timing-function: ease-out;
}

.marker-hover {
  background-color: #80b085;
}

@media screen and (width <= 1024px) {
  .timeline {
    width: 100%;
    padding: 10px 0;
  }

  .timeline-content {
    padding: 0;
  }

  .timeline:before {
    width: 2px;
    left: 8px;
  }

  .timeline-block {
    width: 100%;
    margin-bottom: 30px;
  }

  .timeline-block-right {
    float: none;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
  }
}

.project-card {
  direction: ltr;
  color: #616161;
  background-color: #f8f8f8;
  max-width: 600px;
  margin: 8px;
  padding: 24px;
}

.border-gradient {
  border: 8px solid;
  border-width: 4px;
  border-image-source: conic-gradient(#5c9c73, #272a34, #80b085, #5c9c73);
  border-image-slice: 1;
}

.project-card-header {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  display: flex;
}

.project-card-titlegroup {
  flex-direction: column;
  display: flex;
}

.project-card-title {
  color: #000;
  text-align: right;
  margin: auto;
}

.project-card-subtitle {
  color: gray;
  text-align: right;
  margin: auto 0 auto auto;
}

.project-card-img-content {
  margin: 8px auto;
}

.project-card-description {
  margin-top: 8px;
  margin-bottom: 8px;
  font-family: Roboto, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
}

.float-on-hover {
  vertical-align: middle;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: ease-out;
  display: inline-block;
  transform: perspective(1px)translateZ(0);
  box-shadow: 0 0 1px #0000;
}

.float-on-hover:hover, .float-on-hover:focus, .float-on-hover:active {
  transform: translateY(-8px);
}

.carousel-image {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.carousel-container {
  margin: auto;
  position: relative;
}

.carousel-item {
  width: 100%;
  height: 100%;
}

.prev, .next {
  cursor: pointer;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 3px 3px 0;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  transition: all .6s;
  position: absolute;
  top: 50%;
}

.next {
  border-radius: 3px 0 0 3px;
  right: 0;
}

.prev {
  border-radius: 3px 0 0 3px;
  left: 0;
}

.prev:hover, .next:hover {
  background-color: #000c;
}

.carousel-caption-bottom {
  color: #f2f2f2;
  text-align: center;
  text-shadow: 0 0 10px #000c;
  width: 100%;
  padding: 8px 0;
  font-size: 15px;
  position: absolute;
  bottom: 30px;
}

.carousel-caption-center {
  color: #f2f2f2;
  text-align: center;
  text-shadow: 0 0 10px #000c;
  width: 100%;
  padding: 8px 0;
  font-size: 15px;
  position: absolute;
  top: 45%;
  bottom: 50%;
}

.carousel-caption-top {
  color: #f2f2f2;
  text-align: center;
  text-shadow: 0 0 10px #000c;
  width: 100%;
  padding: 8px 0;
  font-size: 15px;
  position: absolute;
  top: 8px;
}

.dots {
  text-align: center;
  width: 100%;
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
}

.slide-number {
  color: #f2f2f2;
  padding: 8px 12px;
  font-size: 12px;
  position: absolute;
  top: 0;
}

.dot {
  cursor: pointer;
  background-color: #bbb;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  transition: background-color .6s;
  display: inline-block;
}

.active, .dot:hover {
  background-color: #717171;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.pause-icon {
  opacity: 1;
  position: absolute;
  bottom: 2px;
  left: 15px;
}

.pause {
  animation-name: pause;
  animation-duration: .5s;
}

.bar {
  background-color: #ddd;
  border-radius: 0 0 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.progress {
  background-color: #042baa;
  width: 1%;
  height: 5px;
}

.thumbnails {
  align-items: center;
  margin-top: 10px;
  display: flex;
  overflow: scroll;
}

.thumbnails::-webkit-scrollbar {
  display: none;
}

.thumbnail {
  margin: 0 5px;
}

.active-thumbnail {
  border: 3px solid #476ff1;
}

@keyframes pause {
  0% {
    opacity: .2;
  }

  90% {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (width <= 500px) {
  .prev, .next, .carousel-caption-bottom, .carousel-caption-center, .carousel-caption-top, .slide-number {
    font-size: 20px !important;
  }

  .dot {
    width: 4px;
    height: 4px;
  }

  .carousel-container {
    max-height: 250px !important;
  }

  .thumbnail {
    max-width: 70px;
  }

  .carousel-caption-bottom {
    bottom: 25px;
  }
}

h3, h4 {
  margin: 8px 0;
}

p {
  font-size: 14px;
  line-height: 21px;
}

.card-container {
  text-align: center;
  background-color: #f8f8f8;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 800px;
  margin-top: 96px;
  padding: 16px;
  display: flex;
  position: relative;
  box-shadow: 0 10px 20px -10px #000000bf;
}

hr {
  border-top: 1px solid #ebebeb;
  width: 95%;
  margin: 16px auto;
}

.bio {
  color: #616161;
  margin: 8px;
}

.border-gradient-profile {
  border: 8px solid;
  border-width: 4px;
  border-image-source: conic-gradient(#272a34, #5c9c73, #80b085, #5c9c73);
  border-image-slice: 1;
}

.profile-img {
  border: 4px solid #5c9c73;
  border-radius: 50%;
  margin-top: -96px;
}

.buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  display: flex;
}

button.primary {
  cursor: pointer;
  color: #231e39;
  border: 8px solid;
  background-color: #0000;
  background-image: none;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-repeat: no-repeat;
  border-width: 2px;
  border-image-source: linear-gradient(to right, #80b085, #5c9c73);
  border-image-slice: 1;
  padding: 10px 25px;
  font-family: Roboto, Open Sans, Helvetica Neue, sans-serif;
  font-weight: 500;
}

button:hover {
  background-image: linear-gradient(to left, #80b085 0%, #5c9c73 100%), linear-gradient(#80b085 0% 100%), linear-gradient(to right, #5c9c73 0%, #80b085 100%), linear-gradient(to top, #5c9c73 0% 100%);
}

.button-inner {
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

.skills {
  text-align: left;
  background-color: #f8f8f8;
  margin-top: 16px;
  padding: 15px;
}

.skills ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.skills ul li {
  border: 1px solid #80b085;
  border-radius: 2px;
  margin: 0 7px 7px 0;
  padding: 7px;
  font-size: 12px;
  display: inline-block;
}

@media screen and (width <= 1024px) {
  .card-container {
    width: 95%;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  font-family: Roboto, sans-serif;
}

#root, #__next {
  isolation: isolate;
}

body {
  opacity: 1;
  background-color: #1e2129;
  background-image: linear-gradient(#272a34 2px, #0000 0), linear-gradient(90deg, #272a34 2px, #0000 0), linear-gradient(#272a34 1px, #0000 0), linear-gradient(90deg, #272a34 1px, #0000 0);
  background-size: 75px 75px, 75px 75px, 15px 15px, 15px 15px;
}

div, body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

/*# sourceMappingURL=index.886acedb.css.map */
