h3 {
  margin: 8px 0;
}

h4 {
  margin: 8px 0;
}

p {
  font-size: 14px;
  line-height: 21px;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
  width: 80%;
  max-width: 800px;
  padding: 16px;
  background-color: #f8f8f8;
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
  position: relative;
  text-align: center;
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-top: 1px solid #ebebeb;
}

.bio {
  color: #616161;
  margin: 8px
}

.border-gradient-profile {
  border: 8px solid;
  border-image-slice: 1;
  border-width: 4px;
  border-image-source: conic-gradient(#272a34, #5c9c73, #80b085, #5c9c73);
}

.profile-img {
  border: 4px solid #5c9c73;
  border-radius: 50%;
  margin-top: -96px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

button.primary {
  cursor: pointer;
  color: #f8f8f8;
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  padding: 10px 25px;
  background-color: transparent;
  background-image: none;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-repeat: no-repeat;
  color: #231e39;
  border: 8px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to right, #80b085, #5c9c73);
}

button:hover {
  background-image: linear-gradient(to left, #80b085 0%, #5c9c73 100%),
    linear-gradient(to bottom, #80b085 0%, #80b085 100%),
    linear-gradient(to right, #5c9c73 0%, #80b085 100%),
    linear-gradient(to top, #5c9c73 0%, #5c9c73 100%);
}

.button-inner {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.skills {
  background-color: #f8f8f8;
  text-align: left;
  padding: 15px;
  margin-top: 16px;
}

.skills ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skills ul li {
  border: 1px solid #80b085;
  border-radius: 2px;
  display: inline-block;
  font-size: 12px;
  margin: 0 7px 7px 0;
  padding: 7px;
}

@media screen and (max-width: 1024px) {
  .card-container {
    width: 95%;
  }
}
