.wrapper {
  height: 100%;
  width: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;
}

/*---------        TIMELINE       ---------*/

.timeline {
  width: 90%;
  padding: 50px 0;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
}

.timeline-content {
  width: 95%;
  padding: 0 15px;
  color: #666;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 100%;
  background: #ccd1d9;
  z-index: 1;
}

.timeline-block {
  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 16px;
  height: 16px;
  border-radius: 5%;
  background-color: #ccd1d9;
  margin-top: 10px;
  z-index: 9999;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.marker-hover {
  background-color: #80b085;
}

@media screen and (max-width: 1024px) {
  .timeline {
    width: 100%;
    padding: 10px 0;
  }

  .timeline-content {
    padding: 0;
  }

  .timeline:before {
    left: 8px;
    width: 2px;
  }
  .timeline-block {
    width: 100%;
    margin-bottom: 30px;
  }
  .timeline-block-right {
    float: none;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
  }
}
